body{
  margin: 0;
}

.sliding-doors {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10; /* Encima del contenido pero debajo del navbar */
  overflow: hidden;
  background: transparent; /* Fondo transparente */
}

.door {
  width: 50%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 2.5s ease, opacity 1s ease; /* Añadido fade */
  opacity: 1; /* Las puertas son opacas al inicio */
}

.door-left {
  background-image: url("../assets/door1.webp");
  transform: translateX(0);
}

.door-right {
  background-image: url("../assets/door2.webp");
  transform: translateX(0);
}

.sliding-doors.active .door-left {
  transform: translateX(-100%);
}

.sliding-doors.active .door-right {
  transform: translateX(100%);
}


.access-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(135deg, #000000, #272727); /* Fondo metálico */
  border: 2px solid #ff0000; /* Borde rojo */
  border-radius: 8px;
  padding: 15px 60px;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.7), 0 0 20px rgba(255, 0, 0, 0.5);
  transition: all 0.3s ease, box-shadow 0.5s ease;
  z-index: 20;
}

.access-button:hover {
  background: linear-gradient(135deg, #252525, #1a1919); /* Fondo más claro */
  color: white; /* Texto rojo al pasar el cursor */
  border-color: #ff0000; /* Cambia el borde a blanco */
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.8), 0 0 25px rgba(255, 255, 255, 0.6);
  transform: translate(-50%, -50%) scale(1.1); /* Efecto de escala */
}

.access-button.hidden {
  opacity: 0;
  pointer-events: none; /* Evita la interacción */
}

.access-button:active {
  transform: translate(-50%, -50%) scale(0.95); /* Efecto de clic */
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.8), 0 0 10px rgba(255, 255, 255, 0.6);
}
