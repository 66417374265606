.home {
  height: 100vh; /* Ocupa toda la ventana */
  background-image: url("../assets/background.jpg"); /* Ruta de tu fondo */
  background-size: cover; /* Ajusta el tamaño del fondo */
  background-position: center; /* Centra el fondo */
  background-repeat: no-repeat; /* Evita que el fondo se repita */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra verticalmente */
  align-items: center; /* Centra horizontalmente */
  color: white; /* Color del texto */
  text-align: center;
}

.caja {
  width: 60%;
}

.home h1 {
  font-size: 3rem;
  font-weight: bold;
}

.home h1 span {
  color: #E42F1C; /* Cambia el color a rojo */
}

.home h2 {
  font-size: 2rem;
  margin: 10px 0;
}

hr {
  width: 150px;
  height: 3px;
  background-color: #E42F1C;
  border: none;
}

.home p {
  font-size: 1rem;
  margin: 20px 0;
  line-height: 30px;
}

.home .social-icons {
  display: flex;
  justify-content: center; /* Centra los íconos horizontalmente */
  align-items: center; /* Centra los íconos verticalmente */
  gap: 20px;
  margin: 20px 0;
}

.home .social-icons a {
  color: white; /* Color de los íconos */
  font-size: 1.5rem;
  text-decoration: none;
}

.home .social-icons a:hover {
  color: #E42F1C; /* Cambia a rojo al pasar el cursor */
}

.home .download-cv {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: transparent;
  border: 2px solid #E42F1C; /* Borde rojo */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.home .download-cv:hover {
  background-color: #E42F1C;
  color: white;
}

.dynamic-text {
  color: #e42f1c; /* Color rojo */
  font-weight: bold;
  display: inline-block;
  border-right: 2px solid #e42f1c; /* Simula el cursor de escritura */
  animation: blink 0.5s step-end infinite; /* Cursor parpadeante */
}


.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.social-icon {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.social-icon:hover {
  transform: scale(1.2);
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

/* Adaptaciones para pantallas pequeñas */
@media (max-width: 768px) {
  .home {
    padding: 10px; /* Añadir padding para evitar contenido pegado a los bordes */
  }

  .caja {
    width: 90%; /* Hacer la caja más ancha para móviles */
  }

  .home h1 {
    font-size: 2rem; /* Reducir tamaño de fuente */
  }

  .home h1 span {
    font-size: 1.8rem; /* Ajustar tamaño del texto dinámico */
  }

  .home h2 {
    font-size: 1.5rem; /* Reducir tamaño de subtítulo */
  }

  hr {
    width: 100px; /* Ajustar ancho de la línea */
    height: 2px; /* Reducir grosor */
  }

  .home p {
    font-size: 0.9rem; /* Texto más pequeño */
    line-height: 1.5rem; /* Reducir espacio entre líneas */
    margin: 15px 0; /* Reducir márgenes */
  }

  .home .social-icons {
    gap: 15px; /* Reducir espacio entre íconos */
  }

  .home .social-icons a {
    font-size: 1.2rem; /* Reducir tamaño de íconos */
  }

  .home .download-cv {
    padding: 8px 16px; /* Reducir padding del botón */
    font-size: 0.9rem; /* Texto más pequeño */
  }
}

@media (max-width: 480px) {
  .home h1 {
    font-size: 1.8rem; /* Aún más pequeño en pantallas extra pequeñas */
  }

  .home h2 {
    font-size: 1.2rem;
  }

  .home p {
    font-size: 0.8rem;
    line-height: 1.4rem;
  }

  .home .download-cv {
    padding: 6px 12px;
    font-size: 0.8rem;
  }

  hr {
    width: 80px;
    height: 1px;
  }
}
