body {
    margin: 0;
    font-family: Arial, sans-serif;
    height: 100vh;
    background-image: url("./assets/background.jpg"); /* Ruta de tu imagen */
    background-size: cover; /* Asegura que el fondo ocupe toda la ventana */
    background-position: center; /* Centra la imagen */
    background-repeat: no-repeat; /* Evita que se repita */
    width: 100%;
    overflow-x: hidden; /* Evita scroll horizontal */
    overflow-y: auto; /* Habilita scroll vertical */
  }

  
  